import React from "react";
import {Link} from "react-router-dom";
import './site_map.css'

function SiteMap({links}){
    return(
    <div className='site_map'>
        <h1>Site Map</h1>
        <div className='content_map'>
            {links.map((link, index) => (<p key={index}>
                <Link to={`/${link}`}>https://mp3bro.com/{link}</Link>
            </p>))}
        </div>
    </div>
    )
}

export default SiteMap