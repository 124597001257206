import React, {useEffect, useState} from 'react';
import './App.css';
import instr1 from './images/Group 4.png';
import instr2 from './images/Group 5.png';
import arrowImage from './images/arrow.png'
import {BrowserRouter as Router, Route, Routes, Link, Navigate} from "react-router-dom";
import png1 from '../src/images/png1.png'
import SiteMap from "./SiteMap";

const YoutubeLinkFetcher = () => {
    const [link, setLink] = useState('');
    const [data, setData] = useState(null);
    const [isInputFilled, setIsInputFilled] = useState(false);
    const [error, setError] = useState();
    const [rapidAPIKey, setRapidAPIKey] = useState('4abf308e31msh04cb2e8c2b7cd81p1fd720jsnf6647fc09023');

    const getKeyCycle = () => {
        switch (rapidAPIKey) {
            case '450924381cmsh103b1da16cff3fdp1cd378jsn9f6d19d1201a':
                return '4abf308e31msh04cb2e8c2b7cd81p1fd720jsnf6647fc09023';
            case '4abf308e31msh04cb2e8c2b7cd81p1fd720jsnf6647fc09023':
                return '2f90964c2emsh0052228964db820p12f807jsnc27a74f9ed86';
            case '2f90964c2emsh0052228964db820p12f807jsnc27a74f9ed86':
                return '450924381cmsh103b1da16cff3fdp1cd378jsn9f6d19d1201a';
            default:
                return '4abf308e31msh04cb2e8c2b7cd81p1fd720jsnf6647fc09023';
        }
    };

    const getYoutubeId = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[7].length === 11) {
            return match[7];
        } else {
            if (url.length > 1) {
                setError('Invalid YouTube URL');
            } else if (url.length === 0) {
                setError(null);
            }
            return null;
        }
    };

    const fetchData = async () => {
        setError(null);
        const id = getYoutubeId(link);
        const url = `https://youtube-mp36.p.rapidapi.com/dl?id=${id}`;
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': rapidAPIKey,
                'X-RapidAPI-Host': 'youtube-mp36.p.rapidapi.com',
            },
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();
            if (result.status === "processing") {
                setTimeout(fetchData, 1000);
            } else {
                setData(result);
            }
        } catch (error) {
            setError('Failed to fetch data');
            console.error(error);
        }
    };

    useEffect(() => {
        if (link.trim() !== '') {
            setData(null);  // Reset data when link changes
            fetchData();
        }
    }, [link]);

    useEffect(() => {
        setIsInputFilled(link !== '');
    }, [link]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newKey = getKeyCycle();
            setRapidAPIKey(newKey);
        }, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="app">
            <div className='header'>
                <a href='/sitemap'>SiteMap</a>
            </div>
            <div className="content" id='converter'>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <h1 className='h1_heading'>YouTube to MP3 converter</h1>
                    <h2>By MP3Bro</h2>
                </div>
                <br/>
                <div className="container">
                    <p className="heading_p">Paste your link <red>below</red></p>
                    <input
                        type="text"
                        placeholder="https://www.youtube.com/watch?v=..."
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                    {error && <div className='error'><p>{error}</p></div>}
                    <div>
                        <div>
                            {!error && isInputFilled && !data?.link && (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                                    <circle fill="#FFFFFF" stroke="#FFFFFF" stroke-width="15" r="15" cx="40"
                                            cy="100">
                                        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                                 keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite"
                                                 begin="-.4"></animate>
                                    </circle>
                                    <circle fill="#FFFFFF" stroke="#FFFFFF" stroke-width="15" r="15" cx="100"
                                            cy="100">
                                        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                                 keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite"
                                                 begin="-.2"></animate>
                                    </circle>
                                    <circle fill="#FFFFFF" stroke="#FFFFFF" stroke-width="15" r="15" cx="160"
                                            cy="100">
                                        <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                                 keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite"
                                                 begin="0"></animate>
                                    </circle>
                                </svg>)}
                            <a href={data?.link || ''} className={data?.link ? 'red' : 'gray'}>
                                Download MP3
                                {data?.link && <img src={arrowImage} alt="arrow"/>}
                            </a>
                        </div>
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>

            </div>
            <div className='sub_content'>
                <div>
                    <div className='img'></div>
                    <p><b>Written by Robin</b></p>
                </div>
                <p>
                    Let us guess. You were scrolling through your daily dose of YouTube videos. All of a sudden, you hit
                    the play button of a podcast that grips you. But alas, you can't download it to your gallery for
                    later.
                    <br/>
                    <br/>
                    Unfortunately, YouTube provides no direct option to download content to your device. That's why we
                    have built an advanced YouTube MP3 converter tool with which you can download any video to your
                    gallery in MP3 format with just a single click. Read on to get to know everything you need to
                    understand about our brand-new free tool
                </p>
                <h1 className='sub_h1'>What is a YouTube MP3 converter?</h1>
                <p>
                    A <a href='#converter'>YouTube MP3 converter</a> is an online tool that lets you convert YouTube
                    videos to audio format.
                    After successful conversion, you can download it at just a click without any formalities. That means
                    instead of just saving the video on YouTube, you can enjoy it offline through your device at no
                    extra cost.
                </p>
                <h1 className='sub_h1'>Who can use a YouTube MP3 converter?</h1>
                <p>Generally speaking, <a href='#converter'>converter YouTube MP3 tools such as MP3Bro</a> are useful
                    for everyone. However, it is mainly useful
                    for sects like:</p>
                <h2 className='sub_h2'>1. Music Lovers</h2>
                <p>As said earlier, everyone can't afford to keep subscriptions for the top music platforms. For them,
                    the YouTube MP3 converter gives an option to download them for free. This way, they can listen to
                    their favorite music offline without any ads or costs.</p>
                <h2 className='sub_h2'>2. Podcast Enthusiasts</h2>
                <p>YouTube is one of the most popular platforms to listen to podcasts. With a converter YouTube MP3
                    tool, users can download any podcast in MP3 format within seconds. Eventually, they can hear it
                    offline at their convenience, even while multitasking.</p>
                <h2 className='sub_h2'>3. Students</h2>
                <p>A lot of students rely on YouTube for study materials and tutorials. Through a converter tool, they
                    can download those videos in audio format for better review and repetition.
                </p>
                <h2 className='sub_h2'>4. Travelers</h2>
                <p>Travels are indeed more enjoyable with soothing music. But poor internet connections often spoil the
                    mood. That's where the relevance of a YouTube MP3 converter tool comes in. With such tools, you can
                    preserve your playlists in an offline audio format without the need for a stable internet
                    connection.
                </p>
                <h2 className='sub_h2'>5. Audiobook Enthusiasts</h2>
                <p>Audiobooks are rising as one of the most popular formats among Gen Z youth. But one of the main
                    challenges is that most listeners often get busy with something and lose touch with the audiobook. A
                    converter tool helps you to download an audiobook in advance so that you do not need to search for
                    it when you want to return to reading. Most audio players have an in-built feature to play the audio
                    file from where you have stopped.
                </p>
                <h2 className='sub_h2'>6. Content Creators</h2>
                <p>A YouTube MP3 converter helps content creators in a lot of ways. For example, they can use it to
                    extract audio from a YouTube video for remixing or editing purposes.
                </p>
                <h2 className='sub_h2'>7. Anyone who wants to save mobile data!</h2>
                <p>Ever wondered about the daily toll of data that YouTube takes? Well, then <a href='#converter'>our
                    converter YouTube MP3
                </a> tool is exactly for you. If you are someone who listens to repetitive audio like music through
                    YouTube, downloading them as MP3 files can be more data-efficient than streaming the content.
                </p>
                <h1 className='sub_h1'>Features</h1>
                <p>With so many options out there, why should you choose our YouTube to MP3 converter itself? The answer
                    lies in the cool set of features that we offer. Check out some unique features that make us stand
                    apart:</p>
                <h2 className='sub_h2'>1. Compatibility </h2>
                <p>Our tool is compatible with all the major browsers such as Chrome, Firefox, Safari, Edge, etc. So,
                    you need not switch through browsers to download a YouTube video in MP3 format. And you can use it
                    through any device without any lag or glitches.</p>
                <h2 className='sub_h2'>2. High Speed
                </h2>
                <p>No matter how large the video is, you can convert and download it within seconds. Our YouTube MP3
                    converter is designed to process complex video sizes quickly so that you can wrap up the process
                    quickly. On top of that, the tool uses advanced technologies to ensure that the audio size is kept
                    minimum without any quality reduction to facilitate faster downloading. In fact, it just takes
                    roughly under 3 seconds to download a normal YouTube video in MP3 format through our tool.
                </p>
                <h2 className='sub_h2'>3. Easy to Use</h2>
                <p>The best part of our YouTube to MP3 converter tool is its convenience. You need not be tech-savvy to
                    know how to handle it. It is designed specifically for beginners who don't have much idea about
                    video or audio format technologies. The tool comes up with a hassle-free and user-friendly
                    interface. All you need to do is just paste the YouTube video link and hit the download button.
                </p>
                <h2 className='sub_h2'>4. Multiple Format</h2>
                <p>The YouTube MP3 converter tool supports multiple video formats such as MP4, WMA, 3GP WEBM, M4A, FLV,
                    etc. That means you can convert any type of YouTube video directly to MP3 in a convenient manner.
                    This would be really helpful for users who want to hear their favorite music and podcasts offline
                    without the trouble of going through a large conversion cycle.
                </p>
                <h2 className='sub_h2'>5. High Quality
                </h2>
                <p>While using our convert YouTube MP3 tool, you need not worry about the quality. Our tool utilizes
                    modern technologies to extract the audio from your favorite YouTube videos without even a
                    hairbreadth loss in quality. It even optimizes the audio file size for easy download. Only one thing
                    will change after the conversion- file format.
                </p>
                <h2 className='sub_h2'>6. Mobile-friendly
                </h2>
                <p>As per the latest stats, <a
                    href='https://www.statista.com/statistics/277125/share-of-website-traffic-coming-from-mobile-devices/'>more
                    than half</a> of the web traffic comes from mobiles. That's why many
                    websites are designed to be mobile-friendly just like ours. But we take a step ahead by optimizing
                    the audio for mobile devices. We ensure compatibility of the audio file with the prominent mobile
                    audio player. Moreover, we keep the file size to the minimum for everlasting storage on your Android
                    device.
                    And of course, our MP3 converter allows unlimited usage. You won’t be capped nor requested to
                    sign-up, ever!
                </p>
                <h1 className='sub_h1'>How do YouTube MP3 converters really work? </h1>
                <p>Everyone knows that a <a href='#converter'>YouTube MP3 tool</a> works by converting a YouTube video
                    to an audio file and
                    presenting it for download. But how does it work actually? Well, its working is not as complex as
                    you might think. Here's how the entire process happens</p>
                <h2 className='sub_h2'>1. Audio Extraction</h2>
                <p>Most converter tools work through advanced algorithms. First of all, it analyzes and identifies the
                    audio stream from the YouTube video link. Then, it extracts the audio from it without any reduction
                    in the quality or loss of data.</p>
                <h2 className='sub_h2'>2. Encoding the audio
                </h2>
                <p>After successful extraction, the tool converts it to MP3 format. The audio size and quality of the
                    file are determined at this stage. Most tools try to strike a balance so as to ensure that users get
                    the best quality audio files at the lowest size.</p>
                <h2 className='sub_h2'>3. Efficient processing
                </h2>
                <p>Some tools even take a step ahead by using cloud-based processing technology. It can withstand high
                    conversion volume requests easily and ensures a fast conversion process.
                </p>
                <h1 className='sub_h1'>How to use a YouTube MP3 converter?</h1>
                <p>The process of using our converter YouTube MP3 is straightforward. It is designed with a simple
                    3-step copy-paste-download formula to keep user convenience in preference. Here are the steps to use
                    the tool:</p>
                <br/>
                <p>1. <b>Copy the Link: </b>Open YouTube and search for the video that you want to download in audio
                    format. Copy the link to that video.
                </p>
                <p>2. <b>Paste:</b> Visit our YouTube MP3 converter tool and paste the link of the video on the text
                    bar.
                </p>
                <p>3. <b>Download: </b>Click on the 'Download MP3' button. Wait for some seconds until the audio gets
                    downloaded to your device. </p>
                <br/>
                <div className="use">
                    <img src={instr1} alt="Instruction 1"/>
                    <img src={instr2} alt="Instruction 2"/>
                </div>
                <h1 className='sub_h1'>Why Use a YouTube MP3 Converter?</h1>
                <p>Music and podcast platforms are on the rise. So, you might think that YouTube MP3 converters are
                    outdated. Well, the reality is different. YouTube MP3 converter tools are becoming more popular day
                    by day. Have a look at some reasons why such tools still remain relevant:
                </p>
                <h2 className='sub_h2'>1. Free access</h2>
                <p>Most music platforms come at a subscription rate that may be beyond the reach of a common man. But
                    with a
                    convert YouTube MP3 tool, you can convert any kind of YouTube video and download it in audio format
                    for
                    free. That means you get free access to millions of music or podcasts on YouTube, saving your
                    pocket.</p>
                <h2 className='sub_h2'>2. Device Storage
                </h2>
                <p>Even if you pay to subscribe to some prominent music platforms, what would be the result? You can
                    just
                    hear it through the platform, right? But what if you lose access to the account?
                    Well, a YouTube MP3 converter gives a simple solution to these problems. It allows you to save your
                    favorite video in audio format to your device storage. You can easily transfer it to any device
                    without
                    any complications.
                </p>
                <h2 className='sub_h2'>3. Stream in the background</h2>
                <p>One of the biggest drawbacks of YouTube is the lack of a background streaming option. If you want to
                    chat
                    with your bestie, the video pauses. And what next? After the chat, you need to start from scratch
                    the
                    playlist. That's why people are searching more for YouTube MP3 converters to download and hear it
                    conveniently. Simply, those tools help you to balance this so that your daily life will not be
                    affected.</p>
                <h2 className='sub_h2'>4. Easy To Handle</h2>
                <p>MP3 files are always easy to handle as compared to large-sized YouTube videos. Poor network
                    connection
                    and ads always spoil the listening experience on YouTube. With a YouTube MP3 converter tool, you can
                    listen to your favorite audio anywhere at any time, conveniently.</p>
                <h2 className='sub_h2'>5. Free To Access</h2>
                <p>A YouTube MP3 converter tool is perfect for anyone who cannot afford to pay for reputed music
                    streaming
                    platforms. You can get free access to millions of music and podcasts on YouTube and even download
                    them
                    to your device at no extra cost.</p>
                <h1 className='sub_h1'>Why Choose Us?</h1>
                <p>As we all know, the concept of a YouTube MP3 converter is not so new. So, why choose our tool itself?
                    Well, here are some reasons why thousands of users choose us:</p>
                <h2 className='sub_h2'>&#x2022; Free</h2>
                <p>Our convert YouTube MP3 tool is completely free to use. Why spend your valuable pennies to just hear
                    your
                    favourite YouTube music and podcasts offline? We let you download the audio file free of charge
                    while
                    maintaining the same quality as that of the paid ones.</p>
                <h2 className='sub_h2'>&#x2022; No SignUp</h2>
                <p>In our YouTube MP3 converter, you just need one thing to download your favourite audio- YouTube video
                    link. You need not waste your valuable time on any sign-ups or extra formalities. Just paste the
                    link
                    and download the clip. The entire process just takes seconds.</p>
                <h2 className='sub_h2'>&#x2022; Unlimited</h2>
                <p>Do you have tons of videos to convert and download? While our tool does not support batch
                    conversions,
                    you can convert as many videos as you want without any restrictions. That means there is no need to
                    wait
                    for any daily quota to refill.</p>
                <h2 className='sub_h2'>&#x2022; No Installations</h2>
                <p>Our YouTube MP3 converter tool is a web-based tool. Therefore, you need not install any software or
                    tool,
                    risking your privacy. Just visit the website and download as many audio files as you want. It's as
                    simple as that.</p>
                <h2 className='sub_h2'>&#x2022; Safety</h2>
                <p>We consider user data and privacy as the top priority. That's why we have eliminated any need for
                    information collection. Moreover, the links you enter will be automatically deleted without any
                    storage.</p>
                <h2 className='sub_h2'>&#x2022; Automatic Update</h2>
                <p>We automatically update the tool to make it more updated with the latest technologies. Therefore, you
                    will have better access to optimize the audio quality and speed.</p>
                <h2 className='sub_h2'>&#x2022; No Ad</h2>
                <p>Tired of watching countless ads just to download a 30-second audio file? Well, we offer an ad-free
                    experience to users. All you need to do is just copy the video link and wait for about 3 seconds
                    until
                    the audio gets downloaded to your device.</p>
                <h1 className='sub_h1'>5 Best Practices While Converting YouTube Videos to MP3</h1>
                <p>Nowadays, a lot of tools provide YouTube video to MP3 <a href='#converter'>conversion</a>. But not
                    all of
                    them are trustable.
                    Check out 5 tips to know while dealing with online converter YouTube MP3 tools:
                </p>
                <h2 className='sub_h2'>1. SSL Certificate</h2>
                <p>Remember safety is always the top priority while using any website. But how do you ensure the safety
                    of a
                    particular website? Well, the process is simple. Check for a padlock icon in the browser bar. If
                    yes,
                    then the website is secure and has an SSL certificate. That means you are good to go with it.</p>
                <h2 className='sub_h2'>2. Use Ad-blockers
                </h2><p>Many websites come up with dangerous or malicious ads that could cause potential harm to your
                device. It is always better to use an ad blocker to stay safe from such moves. Or, use our website,
                which
                does not use any malicious ad or pop-up.</p>
                <h2 className='sub_h2'>3. High-quality Video</h2><p>The better the YouTube video, the better will be the
                audio quality, too. So, try to pick a video with at least 720p quality for conversion. You need not
                worry
                much about the file size as most tools will optimize the size before making it available for download.
            </p>
                <h2 className='sub_h2'>4. Ease of use</h2><p>Most YouTube MP3 converter tools survive through ad
                incomes.
                They usually try to maximize their revenue by placing ad blocks everywhere. That's why the user
                interface of
                many websites seems clumsy. Eventually, your valuable time gets wasted in spammy ads and pop-ups.
                Therefore,
                always try the tools that offer a neat and clean user interface without any ads.</p>
                <h2 className='sub_h2'>5. Copyright Laws</h2><p>Many YouTube videos have copyrights. Downloading and
                sharing
                it without authorized consent may lead to legal consequences. So, before copying the link ensure that
                the
                video is copyright-free. Especially, if you are planning to use the downloaded MP3 for scopes outside
                personal use.</p>
                <h1 className='sub_h1'>Stats</h1>
                <p>YouTube is one of the most used social media apps currently. However, the usage of YouTube MP3
                    converters
                    may seem doubtful for you. Well, here are some stats that highlight the relevance of YouTube to MP3
                    converters:</p>
                <br/>
                <p>&#x2022; YouTube has over <a
                    href='https://www.globalmediainsight.com/blog/youtube-users-statistics/'>2.70 billion</a> active
                    users as of 2024. The figures are expected to shoot up to
                    2.85 billion by 2025.
                </p><br/>
                <p>&#x2022; Currently, there are over 3.9 billion videos on YouTube. That means you can find almost all
                    popular music and podcasts on the platform.</p><br/>
                <p>&#x2022; YouTube Music has over <a href='https://gitnux.org/youtube-music-statistics/'>77
                    million</a> subscribers and is considered <a href='https://zipdo.co/statistics/youtube-music/'>the
                    third most downloaded</a> music
                    app. With our MP3 converter, you can also download YouTube music MP3!
                </p><br/>
                <p>&#x2022; Music videos account for 93% of YouTube's top-watched content.</p><br/>
                <p>&#x2022; YouTube Shorts is now even <a
                    href='https://socialmarketing90.com/youtube-shorts-statistics/'>more popular
                    than TikTok</a>. And for this reason, our tool also supports
                    YouTube Shorts to MP3 conversion.
                </p><br/>
                <p>&#x2022; YouTube tops the list of the most data-consuming apps on most Gen Z mobile phones. As per
                    estimates, this is the data consumption pattern of YouTube for an hour: 480-660MB per hour for a
                    480p
                    resolution video, 1.2-2.7 GB per hour for 720p, and 2.5-4.1 GB per hour for a 1080p video.
                    Therefore,
                    most users are switching to YouTube to MP3 converters to tackle the internet bills.
                </p>
                <h1 className='sub_h1'>Frequently Answered Questions</h1>
                <p>1. Will anyone know if I downloaded from your converter tool?
                </p><br/>
                <p>No, not at all. All your data is encrypted through an SSL certificate. The audio file will have the
                    same
                    quality and data as of the YouTube video. We will not add any audio watermark to the file. </p><br/>
                <p>2. Are YouTube MP3 converters safe to use?</p><br/>

                <p>Well, not all tools are safe. Even though our YouTube MP3 converter ensures safety through an SSL
                    certificate, many other tools may present harmful ads. Some software that requires installation may
                    come
                    up with dangerous spyware to leak your device data.</p><br/>
                <p>3. Are YouTube MP3 converters illegal?</p><br/>
                <p>No. There are no legal laws that restrict the activities of a YouTube MP3 converter. But there are
                    Copyright laws to protect the intellectual rights of some content. As per laws, videos that have
                    Copyrights should not be downloaded or shared.</p><br/>
                <p>4. How many files can I download?</p><br/>
                <p>There are no restrictions or daily quotas. You can convert and download as many videos as you want.
                    Enjoy!</p><br/>
                <p>5. Can I edit or trim the audio before conversion?
                </p><br/>
                <p>No. You can only submit the link to the YouTube video. Our tool extracts the original audio from that
                    and
                    converts it to an MP3 file. However, after downloading, you can edit the audio file as per your
                    interests through third-party apps.</p><br/>
                <h1 className='sub_h1'>Final Words</h1>
                <p>Listening to music and podcasts are often heartwarming. But poor internet connection and large data
                    consumption could be joy killers. Converting your favorites and downloading them as audio files is
                    the
                    most practical solution to such problems.<br/><br/>That's exactly what <a href='#converter'>our free
                        YouTube to MP3 converter</a> offers.
                    No ads. No sign-ups. Just enter the link
                    and download as many audio files as you want within seconds. Try out our free YouTube MP3 converter
                    now!
                </p>
                <div className='content_button'>
                    <a href='#converter'>Convert YouTube video to MP3 now!</a>
                </div>
            </div>
        </div>);
};

const PathsPage = ({links}) => {
    return (<div>
        <h1>Active Links</h1>
        {links.map((link, index) => (<p key={index}>
            <Link to={`/${link}`}>{link}</Link>
        </p>))}
    </div>);
};

const generateLink = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let link = '';
    for (let i = 0; i < 5; i++) {
        link += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return link;
};


const App = () => {
    const initialActiveLinks = JSON.parse(localStorage.getItem('activeLinks')) || [generateLink(), generateLink(), generateLink()];
    const initialOldLinks = JSON.parse(localStorage.getItem('oldLinks')) || [];
    const [activeLinks, setActiveLinks] = useState(initialActiveLinks);
    const [oldLinks, setOldLinks] = useState(initialOldLinks);

    useEffect(() => {
        localStorage.setItem('activeLinks', JSON.stringify(activeLinks));
        localStorage.setItem('oldLinks', JSON.stringify(oldLinks));
    }, [activeLinks, oldLinks]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newLinks = [...activeLinks];
            const oldLink = newLinks.shift();
            newLinks.push(generateLink());
            setActiveLinks(newLinks);
            setOldLinks([...oldLinks, oldLink]);
        }, 604800000); // Оновлення щотижня
        return () => clearInterval(interval);
    }, [activeLinks, oldLinks]);

    // Функція для визначення першого активного посилання
    const getRandomActiveLink = () => {
        const randomIndex = Math.floor(Math.random() * activeLinks.length);
        return activeLinks[randomIndex] ? `/${activeLinks[randomIndex]}` : "/paths";
    };

    return (
        <Router>
            <Routes>
                <Route path="/paths" element={<PathsPage links={activeLinks}/>}/>
                <Route path="/sitemap" element={<SiteMap links={activeLinks}/>}/>
                {/* Змінений роутер для переадресації */}
                <Route path="/*" element={<Navigate to={getRandomActiveLink()} replace/>}/>
                {/* Залишаємо роути для активних посилань */}
                {activeLinks.map((link, index) => (
                    <Route key={index} path={`/${link}`} element={<YoutubeLinkFetcher/>}/>
                ))}
            </Routes>
        </Router>
    );
};

export default App;